<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <strong>Analisa Slik</strong>
              <a-button
                class="btn btn-outline-primary btn-sm ml-2"
                id="buttontambahdiindex"
                @click="sendToForm('create')"
                >Tambah</a-button
              >
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <!-- <a-month-picker :default-value="moment()" format="MMMM YYYY" class="float-right" @change="selectDate" /> -->
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <a-table
                :bordered="true"
                :columns="columns"
                :data-source="datatable"
                :expandIconAsCell="false"
                :expandIconColumnIndex="1"
                size="small"
                style="
                  margin-left: -26px;
                  margin-right: -26px;
                  margin-top: -21px;
                "
                :customRow="customRow"
                :loading="loading"
                :pagination="{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total) => `Total ${total} items`,
                  pageSizeOptions: ['10', '20', '30'],
                }"
              >
                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px;"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`Search ${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block;"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px;"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  >
                    Search
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px;"
                    @click="() => handleReset(clearFilters)"
                  >
                    Reset
                  </a-button>
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template
                  slot="customRender"
                  slot-scope="text, record, index, column"
                >
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    <a-dropdown :trigger="['contextmenu']">
                      <span v-if="column.dataIndex === 'id_pemohon'">
                        {{
                          record.pemohon === null
                            ? "Tidak ada pemohon"
                            : record.pemohon.nama
                        }}
                      </span>
                      <span v-else> {{ text }}</span>
                      <a-menu slot="overlay">
                        <a-menu-item>
                          <a-icon
                            type="edit"
                            class="text-warning"
                            @click="sendToForm('update', record)"
                          />
                          <span class="ml-1 text-warning">Edit</span>
                        </a-menu-item>
                        <a-menu-item>
                          <a-icon
                            type="delete"
                            class="text-danger"
                            @click="showDeleteConfirm(record)"
                          />
                          <span class="ml-1 text-danger">Hapus</span>
                        </a-menu-item>
                        <a-menu-item>
                          <a-icon
                            type="printer"
                            class="text-success"
                            @click="getExcel(record)"
                          />
                          <span class="ml-1 text-danger">Print</span>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </template>
                </template>
                <template slot="operations" slot-scope="text, record">
                  <a-icon
                    type="edit"
                    class="text-warning"
                    @click="sendToForm('update', record)"
                  />
                  <a-divider type="vertical" />
                  <a-icon
                    type="delete"
                    class="text-danger"
                    @click="showDeleteConfirm(record)"
                  />
                  <a-divider type="vertical" />
                  <a-icon
                    type="printer"
                    class="text-success"
                    @click="getExcel(record)"
                  />
                </template>
                <template slot="dropdown" slot-scope="text, record">
                  <a-dropdown :trigger="['contextmenu']">
                    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> -->
                    <span> {{ text }}</span>
                    <!-- </div> -->
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a-icon
                          type="edit"
                          class="text-warning"
                          @click="sendToForm('update', record)"
                        />
                        <span class="ml-1 text-warning">Edit</span>
                      </a-menu-item>
                      <a-menu-item>
                        <a-icon
                          type="delete"
                          class="text-danger"
                          @click="showDeleteConfirm(record)"
                        />
                        <span class="ml-1 text-danger">Hapus</span>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </template>
                <span slot="tanggal_register" slot-scope="text, record">
                  {{
                    moment(record.pemohon.tglregister, "YYYY-MM-DD").format(
                      "DD-MM-YYYY"
                    )
                  }}
                </span>
                <span slot="details" slot-scope="text, record">
                  {{ record.details.length + " Details Tercatat" }}
                </span>
                <p
                  slot="expandedRowRender"
                  slot-scope="record"
                  style="margin: 0;"
                >
                    <!-- style="
                      margin-left: -26px;
                      margin-right: -26px;
                      margin-top: -21px;
                    " -->
                  <a-table
                    :bordered="true"
                    :columns="columnschild"
                    :data-source="record.details"
                    size="small"
                    :pagination="{
                      hideOnSinglePage: true,
                      defaultPageSize: 10,
                      showQuickJumper: true,
                      showSizeChanger: true,
                      showTotal: (total) => `Total ${total} items`,
                      pageSizeOptions: ['10', '20', '30'],
                    }"
                  >
                    <div
                      slot="filterDropdown"
                      slot-scope="{
                        setSelectedKeys,
                        selectedKeys,
                        confirm,
                        clearFilters,
                        column,
                      }"
                      style="padding: 8px;"
                    >
                      <a-input
                        v-ant-ref="(c) => (searchInput = c)"
                        :placeholder="`Search ${column.title}`"
                        :value="selectedKeys[0]"
                        style="
                          width: 188px;
                          margin-bottom: 8px;
                          display: block;
                        "
                        @change="
                          (e) =>
                            setSelectedKeys(
                              e.target.value ? [e.target.value] : []
                            )
                        "
                        @pressEnter="
                          () =>
                            handleSearch(
                              selectedKeys,
                              confirm,
                              column.dataIndex
                            )
                        "
                      />
                      <a-button
                        type="primary"
                        icon="search"
                        size="small"
                        style="width: 90px; margin-right: 8px;"
                        @click="
                          () =>
                            handleSearch(
                              selectedKeys,
                              confirm,
                              column.dataIndex
                            )
                        "
                      >
                        Search
                      </a-button>
                      <a-button
                        size="small"
                        style="width: 90px;"
                        @click="() => handleReset(clearFilters)"
                      >
                        Reset
                      </a-button>
                    </div>
                    <a-icon
                      slot="filterIcon"
                      slot-scope="filtered"
                      type="search"
                      :style="{ color: filtered ? '#108ee9' : undefined }"
                    />
                    <template
                      slot="customRender"
                      slot-scope="text, record, index, column"
                    >
                      <span
                        v-if="searchText && searchedColumn === column.dataIndex"
                      >
                        <template
                          v-for="(fragment, i) in text
                            .toString()
                            .split(
                              new RegExp(
                                `(?<=${searchText})|(?=${searchText})`,
                                'i'
                              )
                            )"
                        >
                          <mark
                            v-if="
                              fragment.toLowerCase() ===
                              searchText.toLowerCase()
                            "
                            :key="i"
                            class="highlight"
                            >{{ fragment }}</mark
                          >
                          <template v-else>{{ fragment }}</template>
                        </template>
                      </span>
                      <template v-else>
                        <a-dropdown :trigger="['contextmenu']">
                          <span> {{ text }}</span>
                        </a-dropdown>
                      </template>
                    </template>
                    <span slot="nomor" slot-scope="text, record, index">
                      {{ index + 1 }}
                    </span>
                    <span slot="numberformat" slot-scope="text">
                      {{ numberFormat(text) }}
                    </span>
                    <span slot="jenis" slot-scope="text">
                      {{ text === '0' ? "Milik pemohon" : "Milik pasangan pemohon" }}
                    </span>
                    <template slot="footer" slot-scope="currentPageData">
                      <strong>Total BakiDebet: </strong> {{ checkTotal(currentPageData) }}
                    </template>
                  </a-table>
                </p>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { Modal } from 'ant-design-vue'

export default {
  data() {
    return {
      columns: [
        {
          title: 'Actions',
          dataIndex: '#',
          width: 100,
          scopedSlots: {
            customRender: 'operations',
          },
        },
        {
          title: 'Kode Pengajuan',
          dataIndex: 'kode_pengajuan',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kode_pengajuan
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Tanggal',
          dataIndex: 'tgl',
          ellipsis: true,
          scopedSlots: {
            customRender: 'dropdown',
          },
        },
        {
          title: 'Details',
          dataIndex: '',
          ellipsis: true,
          scopedSlots: {
            customRender: 'details',
          },
        },
        // {
        //   title: 'SHM atas nama',
        //   dataIndex: 'namapemilik',
        //   scopedSlots: {
        //     customRender: 'dropdown',
        //   },
        // },
      ],
      columnschild: [
        {
          title: 'No',
          ellipsis: true,
          scopedSlots: {
            customRender: 'nomor',
          },
        },
        {
          title: 'Bank',
          dataIndex: 'bank',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.bank
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'BAKIDEBET',
          dataIndex: 'bakidebet',
          ellipsis: true,
          scopedSlots: {
            customRender: 'numberformat',
          },
        },
        {
          title: 'REALISASI',
          dataIndex: 'realisasi',
          ellipsis: true,
          scopedSlots: {
            customRender: 'numberformat',
          },
        },
        {
          title: 'KOL',
          dataIndex: 'kol',
          ellipsis: true,
          scopedSlots: {
            customRender: 'kol',
          },
        },
        {
          title: 'KETERANGAN',
          dataIndex: 'keterangan',
          ellipsis: true,
          scopedSlots: {
            customRender: 'keterangan',
          },
        },
        {
          title: 'Punya',
          dataIndex: 'jenis',
          ellipsis: true,
          scopedSlots: {
            customRender: 'jenis',
          },
        },
      ],
      datatable: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      dropdownVisibility: false,
      loading: false,
      tgl1: moment().startOf('month').format('YYYY-MM-DD'),
      tgl2: moment().endOf('month').format('YYYY-MM-DD'),
      datenow: '',
    }
  },
  created() {
    this.datenow = lou.datenow()
    this.getAllData()
  },
  methods: {
    moment,
    async getExcel(data) {
      // await lou.getDocumentExcel('analisaslik', record.id)
      const ExcelJS = require('exceljs')
      // const uuid = require('uuid').v4()
      const newWorkbook = new ExcelJS.Workbook()
      newWorkbook.creator = 'Taksasi System By Heasoft'
      newWorkbook.lastModifiedBy = 'Taksasi System By Heasoft'
      newWorkbook.created = new Date()
      newWorkbook.modified = new Date()
      newWorkbook.lastPrinted = new Date()
      newWorkbook.calcProperties.fullCalcOnLoad = true
      newWorkbook.addWorksheet('Analisa Slik')
      const worksheet = newWorkbook.getWorksheet('Analisa Slik')
      worksheet.mergeCells('A1:G1')
      worksheet.mergeCells('A2:B2')
      worksheet.getCell('A1').value = 'HASIL SLIK'
      worksheet.getCell('A2').value = 'BANK'
      worksheet.getCell('C2').value = 'KOL'
      worksheet.getCell('D2').value = 'BAKI DEBET'
      worksheet.getCell('E2').value = 'REALISASI'
      worksheet.getCell('F2').value = 'KETERANGAN'
      worksheet.getCell('G2').value = 'PUNYA'
      worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('C2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('D2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('E2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('F2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('G2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('A1').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('A2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('C2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('D2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('E2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('F2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('G2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('A1').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('A2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('C2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('D2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('E2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('F2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('G2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      var startrow = 3
      var totalbakidebet = 0
      var totalrealisasi = 0
      for (let i = 0; i < data.details.length; i++) {
        const element = data.details[i]
        worksheet.mergeCells(`A${startrow}:B${startrow}`)
        worksheet.getCell(`A${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`C${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`D${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`E${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`F${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`G${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`A${startrow}`).value = element.bank
        worksheet.getCell(`C${startrow}`).value = element.kol
        worksheet.getCell(`D${startrow}`).value = lou.curency(element.bakidebet)
        worksheet.getCell(`E${startrow}`).value = lou.curency(element.realisasi)
        worksheet.getCell(`F${startrow}`).value = element.keterangan
        worksheet.getCell(`G${startrow}`).value = element.jenis === '0' ? 'Pemohon' : 'Pasangan Pemohon'
        totalbakidebet += parseFloat(element.bakidebet)
        totalrealisasi += parseFloat(element.realisasi)
        startrow += 1
      }

      worksheet.mergeCells(`A${startrow}:B${startrow}`)
      worksheet.getCell(`A${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`C${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`D${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`E${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`F${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`G${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      startrow += 1
      worksheet.getCell(`A${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`C${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`D${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`E${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`F${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`G${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.mergeCells(`A${startrow}:B${startrow}`)
      worksheet.getCell(`A${startrow}`).value = 'Jumlah'
      worksheet.getCell(`D${startrow}`).value = lou.curency(totalbakidebet)
      worksheet.getCell(`E${startrow}`).value = lou.curency(totalrealisasi)
      const buffer = await newWorkbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Cetak slik.xlsx'
      link.click()
    },
    async getAllData() {
      // ?dari=' + this.tgl1 + '&sampai=' + this.tgl2
      var response = await lou.customUrlGet2('analisaslik')
      this.datatable = response.data
    },
    checkTotal(currentPageData) {
      // console.log('currentPageData', currentPageData)
      var total = 0
      for (let i = 0; i < currentPageData.length; i++) {
        const el = currentPageData[i]
        // console.log('el', el)
        var bakidebet = el.bakidebet.toString().replace(/\D/g, '')
        total += parseFloat(bakidebet)
      }
      return currentPageData.length === 0 ? '0' : lou.curency(total)
    },
    showUpload() {
      this.$refs.file.click()
    },
    sendToForm(action, edata = {}) {
      localStorage.removeItem('dataeditanalisaslik')
      localStorage.removeItem('dataeditanalisaslik')
      if (action === 'create') {
        localStorage.setItem('dataeditanalisaslik', JSON.stringify({ id: '' }))
      } else {
        localStorage.setItem('dataeditanalisaslik', JSON.stringify(edata))
      }
      this.$router.push('/taksasijaminan/analisaslik/form')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.customUrlDelete2('analisaslik/' + deldata.id)
          this.getAllData()
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // this.$refs.datadetail.open(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
            this.dropdownVisibility = true
            // console.log('this.dropdownVisibility', this.dropdownVisibility)
          },
        },
      }
    },
    // selectDate(date, dateString) {
    //   if (date === null) {
    //     this.tgl1 = moment().startOf('month').format('YYYY-MM-DD')
    //     this.tgl2 = moment().endOf('month').format('YYYY-MM-DD')
    //   } else {
    //     this.tgl1 = date.startOf('month').format('YYYY-MM-DD')
    //     this.tgl2 = date.endOf('month').format('YYYY-MM-DD')
    //   }
    //   this.getAllData()
    // },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    numberFormat(v) {
      return lou.curency(v)
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style></style>
